import {
  CButton,
  CCol,
  CFormCheck,
  CFormInput,
  CFormSwitch,
  CInputGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTooltip,
} from "@coreui/react";
import React, { useEffect } from "react";
import { handleFilterSelectedColumnsMissingData } from "../helpers/missingDataHelper";
import { FilterTypesHeader, removeHiddenRows } from "../helpers/commonHelper";
import { handleFilterSelectedColumnsDuplicateData } from "../helpers/ipAddressCheckHelper";
import SearchIconSvg from "src/assets/svg/SearchIconSvg";
import { handleFilterSelectedColumnsDuplicateRespondentsData } from "../helpers/duplicateRespondentsHelper";
import toast from "react-hot-toast";
import { FilterTypes } from "../commontypes";
import { handleValidateOpenEndedResponses } from "../helpers/validateOpenEndedResponsesHelper";
import { handleFilterIdentifyOutliers } from "../identifyOutliersHelper";
import { handleAssesResponsePattern } from "../helpers/assesResponsePatternsHelper";

const CommonModal = (props) => {
  const [flatLinersPercentageValue, setFlatLinersPercentageValue] = React.useState(
    Number(JSON.parse(localStorage.getItem("flatLinersPercentageValue")) || 100),
  ); // [0, setFlatLinersPercentageValue
  const {
    visibleFiltersModal,
    setVisibleFiltersModal,
    filterTypes,
    setFilteredRow,
    filteredRow,
    columns,
    selectedColumns,
    setSelectedColumns,
    hiddenData,
    setHiddenData,
    handleClearFilter,
    handleFilterTypesSubHeader,
    setShowDialog,
    rows,
  } = props;
  const handleColumnSelection = (e) => {
    const { value, checked } = e.target;
    const _selectedColumn = { ...selectedColumns };
    if (checked) {
      _selectedColumn[filterTypes].push(value);
    } else {
      _selectedColumn[filterTypes] = _selectedColumn[filterTypes].filter((item) => item !== value);
    }
    setSelectedColumns(_selectedColumn);
  };

  const handleFilter = async () => {
    const id = toast.loading("Applying filter...");
    let _result = [];
    let allHiddenData = [];
    if (filterTypes === FilterTypes.CHECK_FOR_MISSING_DATA) {
      const { result, _hiddenData } = handleFilterSelectedColumnsMissingData(
        columns,
        rows,
        selectedColumns,
        hiddenData,
        setHiddenData,
        filterTypes,
      );
      _result = result;
      allHiddenData = _hiddenData;
    }
    if (filterTypes === FilterTypes.IP_ADDRESS_CHECK) {
      const { result, _hiddenData } = handleFilterSelectedColumnsDuplicateData(
        columns,
        rows,
        selectedColumns,
        hiddenData,
        setHiddenData,
        filterTypes,
      );
      _result = result;
      allHiddenData = _hiddenData;
    }

    if (filterTypes === FilterTypes.DUPLICATE_RESPONDENTS) {
      const { result, _hiddenData } = handleFilterSelectedColumnsDuplicateRespondentsData(
        columns,
        rows,
        selectedColumns,
        hiddenData,
        setHiddenData,
        filterTypes,
      );
      _result = result;
      allHiddenData = _hiddenData;
    }

    if (filterTypes === FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES) {
      const { result, _hiddenData } = handleValidateOpenEndedResponses(
        columns,
        rows,
        selectedColumns,
        hiddenData,
        setHiddenData,
        filterTypes,
      );
      _result = result;
      allHiddenData = _hiddenData;
    }

    if (filterTypes === FilterTypes.IDENTIFY_OUTLIERS) {
      const { result, _hiddenData } = handleFilterIdentifyOutliers(
        columns,
        rows,
        selectedColumns,
        hiddenData,
        setHiddenData,
        filterTypes,
      );
      _result = result;
      allHiddenData = _hiddenData;
    }
    if (filterTypes === FilterTypes.ASSES_RESPONSE_PATTERNS) {
      const { result, _hiddenData } = handleAssesResponsePattern(
        columns,
        rows,
        selectedColumns,
        hiddenData,
        setHiddenData,
        filterTypes,
        flatLinersPercentageValue,
      );
      _result = result;
      allHiddenData = _hiddenData;
    }
    toast.success("Filter applied successfully", { id });
    setFilteredRow(_result);
    handleFilterTypesSubHeader(filterTypes, allHiddenData[filterTypes]);
    setVisibleFiltersModal({});
    setShowDialog(true);
  };

  // useEffect(() => {
  //   if (visibleFiltersModal.duplicateRespondents) {
  //     const _selectedColumn = { ...selectedColumns };
  //     _selectedColumn[filterTypes] = columns.map((item, index) => index.toString());
  //     setSelectedColumns(_selectedColumn);
  //   }
  // }, [visibleFiltersModal.duplicateRespondents]);

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    const _selectedColumn = { ...selectedColumns };
    if (checked) {
      _selectedColumn[filterTypes] = columns.map((item, index) => index.toString());
    } else {
      _selectedColumn[filterTypes] = [];
    }
    setSelectedColumns(_selectedColumn);
  };

  const handleColumnHeaderBigText = (column = "") => {
    const size = column.length;
    if (size > 40) {
      return column.slice(0, 20) + " ... " + column.slice(-20);
    }
    return column;
  };
  return (
    <div>
      {" "}
      <>
        <CModal
          size="xl"
          alignment="center"
          visible={
            visibleFiltersModal.missingData ||
            visibleFiltersModal.ipAddressCheck ||
            visibleFiltersModal.duplicateRespondents ||
            visibleFiltersModal.validateOpenEndedResponse ||
            visibleFiltersModal.identifyOutliers ||
            visibleFiltersModal.assesResponse
          }
          onClose={() => setVisibleFiltersModal({})}>
          <CModalHeader className="border-0 px-4">
            <CModalTitle>{FilterTypesHeader(filterTypes)}</CModalTitle>
          </CModalHeader>
          <CModalBody className="px-4 py-0">
            <CRow>
              <CCol className="common-modal-search-box" xs={10}>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search column header"
                    aria-label="Search column header"
                    aria-describedby="Search column header"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary search-icon-btn" type="button">
                      <SearchIconSvg />
                    </button>
                  </div>
                </div>
              </CCol>
              <CCol className="common-modal-switch">
                <CFormSwitch
                  size="xl"
                  label="Select All"
                  id="select-all-checkbox"
                  checked={selectedColumns[filterTypes].length === columns.length}
                  onChange={handleSelectAll}
                />
              </CCol>
            </CRow>

            <div className="bg-gray-50 p-3 text-center border text-dark rounded-0 mb-3">
              <span>{selectedColumns[filterTypes].length} Items selected</span>
            </div>

            <div className="columns-header">
              {columns.length > 0 &&
                columns.map((column, index) => {
                  return (
                    <div
                      key={index}
                      className={`c-checkbox border rounded-2 p-2 ${
                        selectedColumns[filterTypes].includes(index.toString())
                          ? "columns-header-div-active"
                          : "columns-header-div"
                      }`}>
                      <CFormCheck
                        // onChange={handleColumnSelection}
                        className="w-100"
                        onChange={handleColumnSelection}
                        checked={selectedColumns[filterTypes].includes(index.toString())}
                        key={index}
                        id={`data-table-column-${index}`}
                        value={index}
                        label={
                          <CTooltip content={column}>
                            <span>{handleColumnHeaderBigText(column)}</span>
                          </CTooltip>
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </CModalBody>
          <CModalFooter className="border-0 px-4">
            {filterTypes === FilterTypes.ASSES_RESPONSE_PATTERNS && (
              <div className=" flex-grow-1 flex-start">
                <div className="d-flex w-50">
                  <CFormInput
                    className="flex-fill"
                    type="number"
                    min="0"
                    max="100"
                    onChange={(e) => {
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }

                      setFlatLinersPercentageValue(Number(e.target.value));
                    }}
                    defaultValue={flatLinersPercentageValue}
                    placeholder="Flat liners percentage value"
                    aria-label="Flat liners percentage value"
                    aria-describedby="button-addon2"
                  />
                  <CButton
                    onClick={() => {
                      localStorage.setItem(
                        "flatLinersPercentageValue",
                        JSON.stringify(flatLinersPercentageValue),
                      );
                      toast.success("Flat Liners Percentage Value applied successfully");
                      setFlatLinersPercentageValue(flatLinersPercentageValue);
                    }}
                    type="button"
                    color="primary">
                    Apply Flat Liners
                  </CButton>
                </div>
              </div>
            )}
            <div className="flex-end">
              <CButton type="button" className="mx-2" onClick={handleFilter} color="primary">
                Apply Filter
              </CButton>
              <CButton
                className="text-white mx-2"
                type="button"
                onClick={handleClearFilter}
                color="danger">
                Clear Filter
              </CButton>
              <CButton
                className="text-white mx-2"
                type="button"
                color="secondary"
                onClick={() => setVisibleFiltersModal({})}>
                Close
              </CButton>
            </div>
          </CModalFooter>
        </CModal>
      </>
    </div>
  );
};

export default CommonModal;
