import {
  CButton,
  CCard,
  CCardBody,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SearchIconSvg from "src/assets/svg/SearchIconSvg";
import CustomPagination from "src/components/CustomPagination";
import { shareProject } from "src/services/project.services";
import { searchUser } from "src/services/user.services";
import { useDebounce } from "use-debounce";

const SHareCampaignModal = (props) => {
  const { isCampaignShareModal, setIsCampaignShareModal, sharedProject, updateSharedUserData } =
    props;
  const [page, setPage] = React.useState(1);
  const [searchString, setSearchString] = useState("");
  const [debouncedValue] = useDebounce(searchString, 700);
  const [limit, setLimit] = React.useState(5);
  const [sharedUser, setSharedUser] = React.useState([]);
  const [isDisabledShareButton, setIsDisabledShareButton] = React.useState([]);
  const [sharedProjectOwnerId, setSharedProjectOwnerId] = React.useState("");

  const {
    isLoading: isUsersLoading,
    isError: isUsersError,
    data: users,
  } = useQuery(
    ["projects", page, limit, debouncedValue],
    () => searchUser({ page, limit, searchString: debouncedValue }),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  );

  const currentUser = JSON.parse(localStorage.getItem("user"));

  const queryClient = useQueryClient();

  const { mutate: shareProjectToUser, isLoading: isSharedProjectLoading } = useMutation(
    shareProject,
    {
      onSuccess: (data) => {
        if (data.success) {
          const ids = [...isDisabledShareButton];
          ids.push(data.data.sharedTo);
          setIsDisabledShareButton(ids);
          updateSharedUserData(data.data);
        }

        queryClient.invalidateQueries("projects");
        toast.dismiss();
        toast.success(data.message);
      },
      onError: (err) => {
        toast.dismiss();
        toast.error(err.message || "Something went wrong");
      },
    },
  );

  const handleSearchUser = (value) => {
    setSearchString(value);
    setPage(1);
  };
  const handlePageChange = (page) => {
    setPage(page);
  };

  if (isUsersError) {
    toast.dismiss();
    toast.error("Error while fetching users");
  }

  if (isSharedProjectLoading) {
    toast.loading("Sharing project...");
  }

  const handleShareProject = (shareToUser) => {
    const user = JSON.parse(localStorage.getItem("user"));
    shareProjectToUser({
      Project: sharedProject._id,
      sharedBy: user._id,
      sharedTo: shareToUser._id,
    });
  };

  useEffect(() => {
    if (sharedProject) {
      setSharedProjectOwnerId(sharedProject.createdBy._id);
      const sharedUserArray = sharedProject.share;
      let sharedUser = [];
      sharedUserArray.forEach((element) => {
        sharedUser.push(element.sharedTo);
      });

      setSharedUser(sharedUser);
    }
  }, [sharedProject]);
  return (
    <CModal
      size="lg"
      alignment="center"
      visible={isCampaignShareModal}
      onClose={() => {
        setIsCampaignShareModal(false);
      }}>
      <CModalHeader className="border-0 px-4">
        <CModalTitle>Share {sharedProject.name}</CModalTitle>
      </CModalHeader>
      <CModalBody className="px-4 py-0">
        <CRow>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search Users"
              aria-label="Search Users"
              aria-describedby="Search Users"
              onChange={(e) => handleSearchUser(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn btn-outline-secondary search-icon-btn" type="button">
                <SearchIconSvg />
              </button>
            </div>
          </div>
        </CRow>

        <CRow>
          <CTable
            className="table table-striped table-bordered table-sm main-data-table"
            responsive>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>Name</CTableHeaderCell>
                <CTableHeaderCell>Email</CTableHeaderCell>
                <CTableHeaderCell>Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {users &&
                users?.data?.length > 0 &&
                users.data.map((item, index) => (
                  <CTableRow v-for="item in tableItems" key={index}>
                    <CTableDataCell>
                      <div>
                        {item.firstName} {item.lastName}
                      </div>
                    </CTableDataCell>
                    <CTableDataCell>{item.email}</CTableDataCell>

                    <CTableDataCell>
                      {sharedProjectOwnerId !== item._id &&
                        (sharedUser.includes(item._id) ? (
                          <CButton type="button" disabled={true} color="primary">
                            Already Shared
                          </CButton>
                        ) : (
                          <CButton
                            type="button"
                            disabled={isDisabledShareButton.includes(item._id)}
                            onClick={(e) => {
                              handleShareProject(item);
                            }}
                            color="primary">
                            {isDisabledShareButton.includes(item._id) ? "Already Shared" : "Share"}
                          </CButton>
                        ))}

                      {sharedProjectOwnerId === item._id && (
                        <CButton type="button" disabled="true" color="primary">
                          Owner
                        </CButton>
                      )}
                    </CTableDataCell>
                  </CTableRow>
                ))}
            </CTableBody>
          </CTable>
        </CRow>
        <CRow className="flex-center">
          {users && users.data && users.data.length > 0 && (
            <CustomPagination
              currentPage={page || 1}
              totalPages={Math.ceil(users?.count / 5) || 1}
              onPageChange={handlePageChange}
            />
          )}
        </CRow>
      </CModalBody>
      <CModalFooter className="border-0 px-4 flex-end">
        <CButton
          type="button"
          color="secondary"
          className="text-white"
          onClick={() => {
            setIsCampaignShareModal(false);
          }}>
          Close
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default SHareCampaignModal;
