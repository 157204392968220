import { FilterTypes } from "../commontypes";

const handleFilterSelectedColumnsDuplicateRespondentsData = (
  columns,
  rows,
  selectedColumns,
  hiddenData,
  setHiddenData,
  filterTypes,
) => {
  let obj = {};
  let newRows = [];
  const _selectedColumns = selectedColumns[filterTypes];
  const _hiddenData = { ...hiddenData };

  const selectedColumnsNames = [];
  _selectedColumns.forEach((item) => {
    selectedColumnsNames.push(columns[item]);
  });

  const seenValues = new Set(); // Keep track of seen values in the selected columns

  // Filter the rows and keep only the first occurrence of each set of duplicates
  newRows = rows.filter((row) => {
    const selectedValues = selectedColumnsNames.map((colName) => row[colName]);
    const key = selectedValues.join("|"); // Create a unique key based on selected column values

    if (!seenValues.has(key)) {
      seenValues.add(key);
      return true; // Include the row if it's the first occurrence of this set of values
    } else {
      _hiddenData[FilterTypes.DUPLICATE_RESPONDENTS].push(row); // Move duplicates to hidden data
      return false; // Exclude duplicates
    }
  });

  setHiddenData(_hiddenData);

  return { _hiddenData, result: newRows };
};

export { handleFilterSelectedColumnsDuplicateRespondentsData };
