import { FilterTypes } from "../commontypes";
import { removeHiddenRows } from "./commonHelper";

const handleFilterDataRange = (
  columns,
  rows,
  selectedColumns,
  hiddenData,
  setHiddenData,
  filterTypes,
) => {
  const _hiddenData = { ...hiddenData };

  const _selectedColumns = selectedColumns[filterTypes];
  let result = rows.filter((row) => {
    for (let i = 0; i < _selectedColumns.length; i++) {
      const column = _selectedColumns[i];
      if (
        row[columns[column.column]] !== undefined &&
        !isNaN(row[columns[column.column]]) &&
        !isNaN(column.min) &&
        !isNaN(column.max)
      ) {
        if (
          Number(row[columns[column.column]]) <= Number(column.min) ||
          Number(row[columns[column.column]]) >= Number(column.max)
        ) {
          return true;
        }
      }
    }
  });
  _hiddenData[FilterTypes.VALIDATE_DATA_RANGE] = [
    ..._hiddenData[FilterTypes.VALIDATE_DATA_RANGE],
    ...result,
  ];
  setHiddenData(_hiddenData);
  result = removeHiddenRows(rows, _hiddenData);
  return { _hiddenData, result };
};

export { handleFilterDataRange };
