import { FilterTypes } from "../commontypes";
import { removeHiddenRows } from "./commonHelper";

const handleCrossTabulation = (
  columns,
  rows,
  selectedColumns,
  hiddenData,
  setHiddenData,
  filterTypes,
) => {
  const _hiddenData = { ...hiddenData };
  const _selectedColumns = selectedColumns[filterTypes];
  let result = rows.filter((row) => {
    for (let i = 0; i < _selectedColumns.length; i++) {
      const column = _selectedColumns[i];
      const expectedArray = column.expected?.split(",").map((item) => item?.trim()?.toLowerCase());
      const unexpectedArray = column.unexpected
        ?.split(",")
        .map((item) => item?.trim()?.toLowerCase());

      if (row[columns[column.source]] !== undefined && !isNaN(row[columns[column.source]])) {
        if (
          row[columns[column.source]] >= column.min &&
          row[columns[column.source]] <= column.max
        ) {
          // console.log(
          //   isNaN(row[columns[column.target]]),
          //   row[columns[column.target]],
          //   !expectedArray.includes(
          //     isNaN(row[columns[column.target]])
          //       ? row[columns[column.target]]?.trim()?.toLowerCase()
          //       : String(row[columns[column.target]]),
          //   ),
          // );
          if (
            (expectedArray?.length > 0 &&
              !expectedArray.includes(
                isNaN(row[columns[column.target]])
                  ? row[columns[column.target]]?.trim()?.toLowerCase()
                  : String(row[columns[column.target]]),
              )) ||
            (unexpectedArray?.length > 0 &&
              unexpectedArray?.includes(
                isNaN(row[columns[column.target]])
                  ? row[columns[column.target]]?.trim()?.toLowerCase()
                  : String(row[columns[column.target]]),
              ))
          ) {
            return true;
          }
        }
      }
    }
  });
  _hiddenData[FilterTypes.CROSS_TABULATION] = [
    ..._hiddenData[FilterTypes.CROSS_TABULATION],
    ...result,
  ];
  setHiddenData(_hiddenData);
  result = removeHiddenRows(rows, _hiddenData);
  return { _hiddenData, result };
};

export { handleCrossTabulation };
