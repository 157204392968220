import React, { useCallback, useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSpinner,
} from "@coreui/react";
import { FilterTypes } from "./commontypes";
import CommonModal from "./modals/CommonModal";
import { WorkBook, read, utils, writeFile } from "xlsx";
import DataTable from "./DataTable";
import { FilterTypesHeader, delay, removeHiddenRows } from "./helpers/commonHelper";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getFilesById, updateFiles } from "src/services/file.services";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useCallbackPrompt } from "./helpers/custom-hooks/useCallbackPrompt";
import ValidateDataRangeModal from "./modals/ValidateDataRangeModal";
import GoBackSvg from "src/assets/svg/GoBackSvg";
import DeleteModal from "../DeleteModal/DeleteModal";
import CrossTabulationModal from "./modals/CrossTabulationModal";

const ApplyFilters = () => {
  const userInfo = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
  const [filterTypes, setFilterTypes] = React.useState(null);
  const [visibleFiltersModal, setVisibleFiltersModal] = React.useState({});
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filteredRow, setFilteredRow] = useState([]);
  const [selectedColumns, setSelectedColumns] = React.useState({
    [FilterTypes.CHECK_FOR_MISSING_DATA]: [],
    [FilterTypes.IP_ADDRESS_CHECK]: [],
    [FilterTypes.IP_ADDRESS_CHECK_LOCATION]: [],
    [FilterTypes.VALIDATE_DATA_RANGE]: [],
    [FilterTypes.IDENTIFY_OUTLIERS]: [],
    [FilterTypes.CROSS_TABULATION]: [],
    [FilterTypes.ASSES_RESPONSE_PATTERNS]: [],
    [FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES]: [],
    [FilterTypes.DUPLICATE_RESPONDENTS]: [],
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [hiddenData, setHiddenData] = React.useState({
    [FilterTypes.CHECK_FOR_MISSING_DATA]: [],
    [FilterTypes.IP_ADDRESS_CHECK]: [],
    [FilterTypes.IP_ADDRESS_CHECK_LOCATION]: [],
    [FilterTypes.VALIDATE_DATA_RANGE]: [],
    [FilterTypes.IDENTIFY_OUTLIERS]: [],
    [FilterTypes.CROSS_TABULATION]: [],
    [FilterTypes.ASSES_RESPONSE_PATTERNS]: [],
    [FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES]: [],
    [FilterTypes.DUPLICATE_RESPONDENTS]: [],
  });

  const [filterTypesHeader, setFilterTypesHeader] = React.useState("general");
  const [filterTypesSubHeader, setFilterTypesSubHeader] = React.useState([]);
  const [filterTypesSubHeaderSelected, setFilterTypesSubHeaderSelected] = React.useState("all");
  const [visibleRow, setVisibleRow] = React.useState([]);
  const [showDialog, setShowDialog] = React.useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = React.useState(false);
  const [restoreRow, setRestoreRow] = React.useState();
  // const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
  const { id } = useParams();
  // console filteredRow 10 to 25

  const queryClient = useQueryClient();

  const {
    data: fileData,
    isLoading: fileIsLoading,
    isError: fileIsError,
  } = useQuery(["files", id], () => getFilesById(id), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!id,
  });

  const { mutate: updateFilesMutation, isLoading: updateFilesIsLoading } = useMutation(
    updateFiles,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["files", id]);
        toast.dismiss();
        toast.success("File saved successfully");
      },
      onError: () => {
        toast.dismiss();
        toast.error("Error saving file");
      },
    },
  );
  // console.log(selectedColumns, hiddenData, fileData.data);
  useEffect(() => {
    let id;
    if (fileIsLoading) {
      id = toast.loading("Loading file");
    }
    if (fileIsError) {
      toast.error("Error loading file");
    }
    if (updateFilesIsLoading) {
      id = toast.loading("Saving file to database");
    }
    if (fileData) {
      if (filteredRow.length > 0) return;
      toast.dismiss(id);
      setRows(fileData.data.allData.data);
      setFilteredRow(fileData.data.filteredData.data);
      setVisibleRow(fileData.data.filteredData.data);
      setColumns(fileData.data.allData.selectedColumn);
      setHiddenData({
        [FilterTypes.CHECK_FOR_MISSING_DATA]: fileData.data.missingData.data,
        [FilterTypes.IP_ADDRESS_CHECK]: fileData.data.ipAddressDuplicateRespondentsData.data,
        [FilterTypes.IP_ADDRESS_CHECK_LOCATION]: fileData.data.ipAddressLocationCheckData.data,
        [FilterTypes.VALIDATE_DATA_RANGE]: fileData.data.validateDataRangeData.data,
        [FilterTypes.IDENTIFY_OUTLIERS]: fileData.data.identifyOutliersData.data,
        [FilterTypes.CROSS_TABULATION]: fileData.data.crossTabulationData.data,
        [FilterTypes.ASSES_RESPONSE_PATTERNS]: fileData.data.assessResponsePattersData.data,
        [FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES]:
          fileData.data.validateOpenEndedResponseData.data,
        [FilterTypes.DUPLICATE_RESPONDENTS]: fileData.data.duplicateRespondentData.data,
      });

      setSelectedColumns({
        [FilterTypes.CHECK_FOR_MISSING_DATA]: fileData.data.missingData.selectedColumn,
        [FilterTypes.IP_ADDRESS_CHECK]:
          fileData.data.ipAddressDuplicateRespondentsData.selectedColumn,
        [FilterTypes.IP_ADDRESS_CHECK_LOCATION]:
          fileData.data.ipAddressLocationCheckData.selectedColumn,
        [FilterTypes.VALIDATE_DATA_RANGE]: fileData.data.validateDataRangeData.selectedColumn,
        [FilterTypes.IDENTIFY_OUTLIERS]: fileData.data.identifyOutliersData.selectedColumn,
        [FilterTypes.CROSS_TABULATION]: fileData.data.crossTabulationData.selectedColumn,
        [FilterTypes.ASSES_RESPONSE_PATTERNS]:
          fileData.data.assessResponsePattersData.selectedColumn,
        [FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES]:
          fileData.data.validateOpenEndedResponseData.selectedColumn,
        [FilterTypes.DUPLICATE_RESPONDENTS]: fileData.data.duplicateRespondentData.selectedColumn,
      });
    }
    return () => toast.dismiss(id);
  }, [fileData, fileIsLoading, fileIsError, updateFilesIsLoading]);

  const navigate = useNavigate();

  if (!id) {
    navigate("/projects");
  }

  const handleFilter = (filterType) => {
    setFilterTypes(filterType);
    if (filterType === FilterTypes.CHECK_FOR_MISSING_DATA) {
      setVisibleFiltersModal({
        missingData: true,
      });
    }

    if (filterType === FilterTypes.IP_ADDRESS_CHECK) {
      setVisibleFiltersModal({
        ipAddressCheck: true,
      });
    }

    if (filterType === FilterTypes.DUPLICATE_RESPONDENTS) {
      setVisibleFiltersModal({
        duplicateRespondents: true,
      });
    }

    if (filterType === FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES) {
      setVisibleFiltersModal({
        validateOpenEndedResponse: true,
      });
    }

    if (filterType === FilterTypes.VALIDATE_DATA_RANGE) {
      setVisibleFiltersModal({
        validateDataRange: true,
      });
    }

    if (filterType === FilterTypes.IDENTIFY_OUTLIERS) {
      setVisibleFiltersModal({
        identifyOutliers: true,
      });
    }

    if (filterType === FilterTypes.ASSES_RESPONSE_PATTERNS) {
      setVisibleFiltersModal({
        assesResponse: true,
      });
    }
    if (filterType === FilterTypes.CROSS_TABULATION) {
      setVisibleFiltersModal({
        crossTabulation: true,
      });
    }
  };
  useEffect(() => {
    setFilterTypesSubHeader([]);
    // setFilterTypesSubHeaderSelected("all");
    const _filterTypesSubHeader = [];

    if (hiddenData[FilterTypes.CHECK_FOR_MISSING_DATA].length > 0)
      _filterTypesSubHeader.push(FilterTypes.CHECK_FOR_MISSING_DATA);
    if (hiddenData[FilterTypes.VALIDATE_DATA_RANGE].length > 0)
      _filterTypesSubHeader.push(FilterTypes.VALIDATE_DATA_RANGE);

    if (hiddenData[FilterTypes.IDENTIFY_OUTLIERS].length > 0)
      _filterTypesSubHeader.push(FilterTypes.IDENTIFY_OUTLIERS);

    if (hiddenData[FilterTypes.ASSES_RESPONSE_PATTERNS].length > 0)
      _filterTypesSubHeader.push(FilterTypes.ASSES_RESPONSE_PATTERNS);

    if (hiddenData[FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES].length > 0)
      _filterTypesSubHeader.push(FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES);

    if (hiddenData[FilterTypes.CROSS_TABULATION].length > 0)
      _filterTypesSubHeader.push(FilterTypes.CROSS_TABULATION);

    if (hiddenData[FilterTypes.DUPLICATE_RESPONDENTS].length > 0)
      _filterTypesSubHeader.push(FilterTypes.DUPLICATE_RESPONDENTS);

    if (hiddenData[FilterTypes.IP_ADDRESS_CHECK].length > 0)
      _filterTypesSubHeader.push(FilterTypes.IP_ADDRESS_CHECK);

    if (hiddenData[FilterTypes.IP_ADDRESS_CHECK_LOCATION].length > 0)
      _filterTypesSubHeader.push(FilterTypes.IP_ADDRESS_CHECK_LOCATION);

    setFilterTypesSubHeader(_filterTypesSubHeader);
  }, [hiddenData, filterTypesHeader]);

  const handleFilterTypesSubHeader = (filterType, allData = null) => {
    setFilterTypesSubHeaderSelected(filterType);
    if (filterType === "all") {
      setVisibleRow(allData || filteredRow);
    } else {
      if (allData && allData.length === 0) {
        toast.error("No data found");
        setVisibleRow(filteredRow);
        setFilterTypesSubHeaderSelected("all");
        return;
      }
      const _filteredRow = hiddenData[filterType];
      setVisibleRow(allData || _filteredRow);
    }
  };
  const handleClearFilter = () => {
    const id = toast.loading("Clearing filter...");
    const _selectedColumns = { ...selectedColumns };
    _selectedColumns[filterTypes] = [];
    setSelectedColumns(_selectedColumns);
    const _hiddenData = { ...hiddenData };
    _hiddenData[filterTypes] = [];
    setHiddenData(_hiddenData);
    const _rows = removeHiddenRows(rows, _hiddenData);
    setFilteredRow(_rows);
    setVisibleRow(_rows);
    setFilterTypesSubHeaderSelected("all");
    const _filterTypesSubHeader = [...filterTypesSubHeader];
    const index = _filterTypesSubHeader.indexOf(filterTypes);
    if (index > -1) {
      _filterTypesSubHeader.splice(index, 1);
    }
    setFilterTypesSubHeader(_filterTypesSubHeader);
    toast.success("Filter cleared", { id });
    setVisibleFiltersModal({});
    setShowDialog(true);
  };

  const saveToDb = useCallback(async () => {
    const data = {
      filteredData: {
        data: filteredRow,
      },
      missingData: {
        selectedColumn: selectedColumns[FilterTypes.CHECK_FOR_MISSING_DATA],
        data: hiddenData[FilterTypes.CHECK_FOR_MISSING_DATA],
      },
      ipAddressDuplicateRespondentsData: {
        selectedColumn: selectedColumns[FilterTypes.IP_ADDRESS_CHECK],
        data: hiddenData[FilterTypes.IP_ADDRESS_CHECK],
      },
      ipAddressLocationCheckData: {
        selectedColumn: selectedColumns[FilterTypes.IP_ADDRESS_CHECK_LOCATION],
        data: hiddenData[FilterTypes.IP_ADDRESS_CHECK_LOCATION],
      },
      validateDataRangeData: {
        selectedColumn: selectedColumns[FilterTypes.VALIDATE_DATA_RANGE],
        data: hiddenData[FilterTypes.VALIDATE_DATA_RANGE],
      },
      identifyOutliersData: {
        selectedColumn: selectedColumns[FilterTypes.IDENTIFY_OUTLIERS],
        data: hiddenData[FilterTypes.IDENTIFY_OUTLIERS],
      },
      crossTabulationData: {
        selectedColumn: selectedColumns[FilterTypes.CROSS_TABULATION],
        data: hiddenData[FilterTypes.CROSS_TABULATION],
      },
      assessResponsePattersData: {
        selectedColumn: selectedColumns[FilterTypes.ASSES_RESPONSE_PATTERNS],
        data: hiddenData[FilterTypes.ASSES_RESPONSE_PATTERNS],
      },
      validateOpenEndedResponsesData: {
        selectedColumn: selectedColumns[FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES],
        data: hiddenData[FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES],
      },
      duplicateRespondentsData: {
        selectedColumn: selectedColumns[FilterTypes.DUPLICATE_RESPONDENTS],
        data: hiddenData[FilterTypes.DUPLICATE_RESPONDENTS],
      },

      filteredDataCount: filteredRow.length,
      missingDataCount: hiddenData[FilterTypes.CHECK_FOR_MISSING_DATA].length,
      validateDataRangeDataCount: hiddenData[FilterTypes.VALIDATE_DATA_RANGE].length,
      identifyOutliersDataCount: hiddenData[FilterTypes.IDENTIFY_OUTLIERS].length,
      crossTabulationDataCount: hiddenData[FilterTypes.CROSS_TABULATION].length,
      assessResponsePattersDataCount: hiddenData[FilterTypes.ASSES_RESPONSE_PATTERNS].length,
      validateOpenEndedResponseDataCount:
        hiddenData[FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES].length,
      duplicateRespondentDataCount: hiddenData[FilterTypes.DUPLICATE_RESPONDENTS].length,
      ipAddressLocationCheckDataCount: hiddenData[FilterTypes.IP_ADDRESS_CHECK_LOCATION].length,
      ipAddressDuplicateRespondentsDataCount: hiddenData[FilterTypes.IP_ADDRESS_CHECK].length,
    };

    updateFilesMutation({
      id: fileData.data._id,
      data,
    });
    setShowDialog(false);
  }, [filteredRow, hiddenData, selectedColumns, fileData]);

  const handleReport = () => {
    navigate("/report/" + id);
  };

  const handleAllDataDownload = () => {
    const _hiddenData = { ...hiddenData };
    let allData = [];
    const missingDataCollection = [];
    const ipAddressCheckCollection = [];
    const ipAddressCheckLocationCollection = [];
    const validateDataRangeCollection = [];
    const identifyOutliersCollection = [];
    const crossTabulationCollection = [];
    const assesResponsePatternsCollection = [];
    const validateOpenEndedResponsesCollection = [];
    const duplicateRespondentsCollection = [];
    for (let i = 0, j = 0; i < filteredRow.length; i++, j++) {
      const _filteredRow = { filterType: "none", ...filteredRow[i] };
      allData.push(_filteredRow);
    }
    if (_hiddenData[FilterTypes.CHECK_FOR_MISSING_DATA].length > 0) {
      //add filtertype missing data
      const missingData = _hiddenData[FilterTypes.CHECK_FOR_MISSING_DATA];
      for (let i = 0; i < missingData.length; i++) {
        const _missingdata = { ...missingData[i], filterType: "Missing Data" };
        missingDataCollection.push(_missingdata);
      }
    }
    if (_hiddenData[FilterTypes.IP_ADDRESS_CHECK].length > 0) {
      //add filtertype ip address check
      const ipAddressCheck = _hiddenData[FilterTypes.IP_ADDRESS_CHECK];
      for (let i = 0; i < ipAddressCheck.length; i++) {
        const _ipAddressCheck = { ...ipAddressCheck[i], filterType: "IP Address Check" };
        ipAddressCheckCollection.push(_ipAddressCheck);
      }
    }

    if (_hiddenData[FilterTypes.IP_ADDRESS_CHECK_LOCATION].length > 0) {
      //add filtertype ip address check location
      const ipAddressCheckLocation = _hiddenData[FilterTypes.IP_ADDRESS_CHECK_LOCATION];
      for (let i = 0; i < ipAddressCheckLocation.length; i++) {
        const _ipAddressCheckLocation = {
          filterType: "IP Address Check Location",
          ...ipAddressCheckLocation[i],
        };
        ipAddressCheckLocationCollection.push(_ipAddressCheckLocation);
      }
    }

    if (_hiddenData[FilterTypes.VALIDATE_DATA_RANGE].length > 0) {
      //add filtertype validate data range
      const validateDataRange = _hiddenData[FilterTypes.VALIDATE_DATA_RANGE];
      for (let i = 0; i < validateDataRange.length; i++) {
        const _validateDataRange = { ...validateDataRange[i], filterType: "Validate Data Range" };
        validateDataRangeCollection.push(_validateDataRange);
      }
    }
    if (_hiddenData[FilterTypes.IDENTIFY_OUTLIERS].length > 0) {
      //add filtertype identify outliers
      const identifyOutliers = _hiddenData[FilterTypes.IDENTIFY_OUTLIERS];
      for (let i = 0; i < identifyOutliers.length; i++) {
        const _identifyOutliers = { ...identifyOutliers[i], filterType: "Identify Outliers" };
        identifyOutliersCollection.push(_identifyOutliers);
      }
    }

    if (_hiddenData[FilterTypes.CROSS_TABULATION].length > 0) {
      //add filtertype cross tabulation
      const crossTabulation = _hiddenData[FilterTypes.CROSS_TABULATION];
      for (let i = 0; i < crossTabulation.length; i++) {
        const _crossTabulation = { ...crossTabulation[i], filterType: "Cross Tabulation" };
        crossTabulationCollection.push(_crossTabulation);
      }
    }

    if (_hiddenData[FilterTypes.ASSES_RESPONSE_PATTERNS].length > 0) {
      //add filtertype asses response patterns
      const assesResponsePatterns = _hiddenData[FilterTypes.ASSES_RESPONSE_PATTERNS];
      for (let i = 0; i < assesResponsePatterns.length; i++) {
        const _assesResponsePatterns = {
          ...assesResponsePatterns[i],
          filterType: "Asses Response Patterns",
        };
        assesResponsePatternsCollection.push(_assesResponsePatterns);
      }
    }

    if (_hiddenData[FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES].length > 0) {
      //add filtertype validate open ended responses
      const validateOpenEndedResponses = _hiddenData[FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES];
      for (let i = 0; i < validateOpenEndedResponses.length; i++) {
        const _validateOpenEndedResponses = {
          ...validateOpenEndedResponses[i],
          filterType: "Validate Open Ended Responses",
        };
        validateOpenEndedResponsesCollection.push(_validateOpenEndedResponses);
      }
    }

    if (_hiddenData[FilterTypes.DUPLICATE_RESPONDENTS].length > 0) {
      //add filtertype duplicate respondents
      const duplicateRespondents = _hiddenData[FilterTypes.DUPLICATE_RESPONDENTS];
      for (let i = 0; i < duplicateRespondents.length; i++) {
        const _duplicateRespondents = {
          ...duplicateRespondents[i],
          filterType: "Duplicate Respondents",
        };
        duplicateRespondentsCollection.push(_duplicateRespondents);
      }
    }
    for (let i = 0; i < allData.length; i++) {
      delete allData[i].uniqueId;
    }

    allData = [
      ...allData,
      ...missingDataCollection,
      ...ipAddressCheckCollection,
      ...ipAddressCheckLocationCollection,
      ...validateDataRangeCollection,
      ...identifyOutliersCollection,
      ...crossTabulationCollection,
      ...assesResponsePatternsCollection,
      ...validateOpenEndedResponsesCollection,
      ...duplicateRespondentsCollection,
    ];
    const fileName = "datawithfilters";
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(allData);
    worksheet;
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary XLSX file and save it
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${fileName}.xlsx`);
  };

  const handleDownload = () => {
    let _visibleRow = [...visibleRow];
    for (let i = 0; i < _visibleRow.length; i++) {
      delete _visibleRow[i].uniqueId;
    }
    const fileName = "data";
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(_visibleRow);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary XLSX file and save it
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${fileName}.xlsx`);
  };

  const handleRestore = useCallback((row) => {
    setRestoreRow(row);
    setVisibleDeleteModal(true);
  }, []);

  const submitDelete = () => {
    const _hiddenData = { ...hiddenData };
    const filteredHiddenData = _hiddenData[filterTypesSubHeaderSelected].filter(
      (item) => item.uniqueId !== restoreRow.uniqueId,
    );
    _hiddenData[filterTypesSubHeaderSelected] = filteredHiddenData;
    let isAvailableOtherHiddenData = false;
    //check if restore row is available in other hidden data
    for (const key in _hiddenData) {
      if (Object.hasOwnProperty.call(_hiddenData, key)) {
        const element = _hiddenData[key];
        if (element.find((item) => item.uniqueId === restoreRow.uniqueId)) {
          isAvailableOtherHiddenData = true;
          break;
        }
      }
    }
    if (!isAvailableOtherHiddenData) {
      const _filteredRow = [...filteredRow];
      let index;
      for (let i = 0; i < filteredRow.length; i++) {
        if (filteredRow[i].uniqueId > restoreRow.uniqueId) {
          index = i;
          break;
        }
      }
      _filteredRow.splice(index, 0, restoreRow);
      setFilteredRow(_filteredRow);
    }

    setRestoreRow({});
    setHiddenData(_hiddenData);
    setVisibleRow(filteredHiddenData);
  };
  return (
    <>
      <div className="bg-white">
        <CCard className="border-0 rounded-0">
          <CCardBody>
            <CRow>
              <CCol md={12}>
                <div className="campaign-card-back">
                  <CButton
                    color="light"
                    variant="ghost"
                    className="text-uppercase px-0"
                    onClick={() => navigate(-1)}>
                    <GoBackSvg /> Back
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </div>
      <div className="bg-white mb-3">
        <CCard className="border-0 bg-gray-50 rounded-0 border-bottom">
          <CCardBody>
            <CRow>
              <CCol md={9}>
                <div>
                  <ul className="nav nav-underline apply-filters-filter-headers bg-transparent">
                    <li onClick={() => setFilterTypesHeader("general")} className="nav-item">
                      <a
                        className={`nav-link ${filterTypesHeader === "general" ? "active" : ""}`}
                        aria-current="page">
                        General Filter
                      </a>
                    </li>
                    <li onClick={() => setFilterTypesHeader("advanced")} className="nav-item">
                      <a className={`nav-link ${filterTypesHeader === "advanced" ? "active" : ""}`}>
                        Advance Filter
                      </a>
                    </li>
                  </ul>
                </div>
              </CCol>
              <CCol md={3}>
                <div className="d-flex justify-content-end gap-2">
                  {userInfo?.permission?.editFile ? (
                    <CButton
                      onClick={saveToDb}
                      type="button"
                      color="light"
                      className="text-primary">
                      Save
                    </CButton>
                  ) : (
                    ""
                  )}

                  <CButton
                    onClick={handleReport}
                    type="button"
                    color="light"
                    className="text-primary">
                    Report
                  </CButton>
                  <CDropdown>
                    <CDropdownToggle color="primary">Actions</CDropdownToggle>
                    <CDropdownMenu>
                      {/* <CDropdownItem className="cursor-pointer">Download as PDF</CDropdownItem> */}
                      <CDropdownItem className="cursor-pointer" onClick={handleDownload}>
                        Download selected tab as Excel
                      </CDropdownItem>

                      <CDropdownItem className="cursor-pointer" onClick={handleAllDataDownload}>
                        Download all data as Excel
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </div>

      <div className="bg-white mb-3">
        <CCard className="rounded-0 border-0">
          <CCardBody>
            <div className="apply-filters-btn-wrapper py-0">
              {filterTypesHeader === "general" ? (
                <>
                  <CButton
                    type="button"
                    color="light"
                    onClick={() => handleFilter(FilterTypes.CHECK_FOR_MISSING_DATA)}
                    className={`filter-btn ${
                      hiddenData[FilterTypes.CHECK_FOR_MISSING_DATA].length > 0 ? "" : ""
                    }`}>
                    Check For Missing Data
                  </CButton>

                  <CButton
                    onClick={() => handleFilter(FilterTypes.IP_ADDRESS_CHECK)}
                    type="button"
                    color="light"
                    className={`filter-btn ${
                      hiddenData[FilterTypes.IP_ADDRESS_CHECK].length > 0 ? "" : ""
                    }`}>
                    IP Address Check (Duplicate Respondents)
                  </CButton>
                  <CButton
                    type="button"
                    color="light"
                    onClick={() => handleFilter(FilterTypes.DUPLICATE_RESPONDENTS)}
                    className={`filter-btn  ${
                      hiddenData[FilterTypes.DUPLICATE_RESPONDENTS].length > 0 ? "" : ""
                    }`}>
                    Duplicate Respondents (BOTS)
                  </CButton>
                  <CButton
                    type="button"
                    color="light"
                    onClick={() => handleFilter(FilterTypes.VALIDATE_DATA_RANGE)}
                    className={`filter-btn ${
                      hiddenData[FilterTypes.VALIDATE_DATA_RANGE].length > 0 ? "" : ""
                    }`}>
                    Validate Data Range
                  </CButton>
                </>
              ) : (
                <>
                  <CButton
                    type="button"
                    color="light"
                    onClick={() => handleFilter(FilterTypes.ASSES_RESPONSE_PATTERNS)}
                    className={`filter-btn${
                      hiddenData[FilterTypes.ASSES_RESPONSE_PATTERNS].length > 0 ? "" : ""
                    }`}>
                    Assess Response Patterns (Flat Liners)
                  </CButton>
                  <CButton
                    type="button"
                    color="light"
                    onClick={() => handleFilter(FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES)}
                    className={`filter-btn ${
                      hiddenData[FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES].length > 0 ? "" : ""
                    }`}>
                    Validate Open-Ended Responses
                  </CButton>

                  <CButton
                    type="button"
                    color="light"
                    onClick={() => handleFilter(FilterTypes.IDENTIFY_OUTLIERS)}
                    className={`filter-btn ${
                      hiddenData[FilterTypes.IDENTIFY_OUTLIERS].length > 0 ? "" : ""
                    }`}>
                    Identify Outlires
                  </CButton>
                  <CButton
                    type="button"
                    color="light"
                    onClick={() => handleFilter(FilterTypes.CROSS_TABULATION)}
                    className={`filter-btn ${
                      hiddenData[FilterTypes.CROSS_TABULATION].length > 0 ? "" : ""
                    }`}>
                    Cross-Tabulation And Consistency Checks
                  </CButton>

                  {/* <CButton
              type="button"
              color="light"
              disabled
              className={`filter-btn ${
                hiddenData[FilterTypes.IP_ADDRESS_CHECK_LOCATION].length > 0
                  ? ""
                  : ""
              }`}>
              IP Address Check (Location Checker)
            </CButton> */}
                </>
              )}
            </div>
          </CCardBody>
        </CCard>
      </div>
      <div className="bg-white mb-3">
        <ul className="nav nav-underline apply-filters-filter-headers rounded-0 pt-3 px-3">
          <li onClick={() => handleFilterTypesSubHeader("all")} className="nav-item">
            <a
              className={`nav-link ${filterTypesSubHeaderSelected === "all" ? "active" : ""}`}
              aria-current="page">
              Filtered Data
            </a>
          </li>
          {filterTypesSubHeader.length > 0 &&
            filterTypesSubHeader.map((header, index) => (
              <li
                key={index}
                onClick={() => handleFilterTypesSubHeader(header)}
                className="nav-item">
                <a
                  className={`nav-link ${filterTypesSubHeaderSelected === header ? "active" : ""}`}>
                  {FilterTypesHeader(header, true)}
                </a>
              </li>
            ))}
        </ul>

        <div className="p-3">
          <div className="bg-gray-50 p-3 text-center border text-dark rounded-0">
            Total {visibleRow.length} number of rows filtered
          </div>
        </div>

        {isLoading ? (
          <div className="spinner">
            <CSpinner color="primary" />
          </div>
        ) : (
          <CCard className="rounded-3 border-0">
            <CCardBody className="campaign-card">
              <CRow>
                <DataTable
                  rows={visibleRow}
                  columns={columns}
                  filterTypesSubHeaderSelected={filterTypesSubHeaderSelected}
                  handleRestore={handleRestore}
                />
              </CRow>
            </CCardBody>
          </CCard>
        )}

        {(visibleFiltersModal.missingData ||
          visibleFiltersModal.ipAddressCheck ||
          visibleFiltersModal.duplicateRespondents ||
          visibleFiltersModal.validateOpenEndedResponse ||
          visibleFiltersModal.identifyOutliers ||
          visibleFiltersModal.assesResponse) && (
          <CommonModal
            visibleFiltersModal={visibleFiltersModal}
            setVisibleFiltersModal={setVisibleFiltersModal}
            filterTypes={filterTypes}
            rows={rows}
            setRows={setRows}
            setFilteredRow={setFilteredRow}
            filteredRow={filteredRow}
            columns={columns}
            setColumns={setColumns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hiddenData={hiddenData}
            setHiddenData={setHiddenData}
            handleClearFilter={handleClearFilter}
            setVisibleRow={setVisibleRow}
            handleFilterTypesSubHeader={handleFilterTypesSubHeader}
            setShowDialog={setShowDialog}
          />
        )}

        {visibleFiltersModal.validateDataRange && (
          <ValidateDataRangeModal
            visibleFiltersModal={visibleFiltersModal}
            setVisibleFiltersModal={setVisibleFiltersModal}
            filterTypes={filterTypes}
            rows={rows}
            setRows={setRows}
            setFilteredRow={setFilteredRow}
            filteredRow={filteredRow}
            columns={columns}
            setColumns={setColumns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hiddenData={hiddenData}
            setHiddenData={setHiddenData}
            handleClearFilter={handleClearFilter}
            setVisibleRow={setVisibleRow}
            handleFilterTypesSubHeader={handleFilterTypesSubHeader}
            setShowDialog={setShowDialog}
          />
        )}
        {visibleFiltersModal.crossTabulation && (
          <CrossTabulationModal
            visibleFiltersModal={visibleFiltersModal}
            setVisibleFiltersModal={setVisibleFiltersModal}
            filterTypes={filterTypes}
            rows={rows}
            setRows={setRows}
            setFilteredRow={setFilteredRow}
            filteredRow={filteredRow}
            columns={columns}
            setColumns={setColumns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hiddenData={hiddenData}
            setHiddenData={setHiddenData}
            handleClearFilter={handleClearFilter}
            setVisibleRow={setVisibleRow}
            handleFilterTypesSubHeader={handleFilterTypesSubHeader}
            setShowDialog={setShowDialog}
          />
        )}

        <DeleteModal
          visibleDeleteModal={visibleDeleteModal}
          setVisibleDeleteModal={setVisibleDeleteModal}
          submitDelete={submitDelete}
          message="Are you sure want to restore this?"
          btnText="Restore"
        />
      </div>
    </>
  );
};

export default ApplyFilters;
