const FilterTypes = {
  VALIDATE_DATA_RANGE: "VALIDATE_DATA_RANGE",
  CHECK_FOR_MISSING_DATA: "CHECK_FOR_MISSING_DATA",
  IDENTIFY_OUTLIERS: "IDENTIFY_OUTLIERS",
  CROSS_TABULATION: "CROSS_TABULATION",
  ASSES_RESPONSE_PATTERNS: "ASSES_RESPONSE_PATTERNS",
  VALIDATE_OPEN_ENDED_RESPONSES: "VALIDATE_OPEN_ENDED_RESPONSES",
  DUPLICATE_RESPONDENTS: "DUPLICATE_RESPONDENTS",
  IP_ADDRESS_CHECK: "IP_ADDRESS_CHECK",
  IP_ADDRESS_CHECK_LOCATION: "IP_ADDRESS_CHECK_LOCATION",
};

export { FilterTypes };
