import { FilterTypes } from "../commontypes";
import { removeHiddenRows } from "./commonHelper";
import { testGibberish } from "./GebbrishDetector";
import { stringSimilarity } from "string-similarity-js";

const handleValidateOpenEndedResponses = (
  columns,
  rows,
  selectedColumns,
  hiddenData,
  setHiddenData,
  filterTypes,
) => {
  const _hiddenData = { ...hiddenData };
  _hiddenData[FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES] = [];
  const _selectedColumns = selectedColumns[filterTypes];
  let result = rows.filter((row) => {
    for (let i = 0; i < _selectedColumns?.length; i++) {
      const string = row[columns[_selectedColumns[i]]];
      if (!string || string.length < 0 || !isNaN(string)) return false;
      console.log({ string });
      if (
        testGibberish(string) ||
        stringSimilarity(columns[_selectedColumns[i]], string) > 0.5 ||
        string.length === 1
      )
        return true;
    }
  });
  _hiddenData[FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES] = [
    ..._hiddenData[FilterTypes.VALIDATE_OPEN_ENDED_RESPONSES],
    ...result,
  ];
  setHiddenData(_hiddenData);
  result = removeHiddenRows(rows, _hiddenData);
  return { _hiddenData, result };
};

export { handleValidateOpenEndedResponses };
